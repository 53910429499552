import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import './LoginPage.scss'
import { Link } from 'react-router-dom'
import APIService from '../../services/api'
import { Button } from '../../atoms/Button/Button'

const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const PasswordChangePage = () => {
  const navigate = useNavigate()
  const query = useQuery()
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const [passwordError, setPasswordError] = useState<string | null>(null)
  // const [passwordConfirmError, setPasswordConfirmError] = useState<
  //   string | null
  // >(null)
  const [genericError, setGenericError] = useState<string | null>(null)
  const timerLogin = useRef<NodeJS.Timeout>()

  const handleChangePassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setPasswordError(null)
    setIsSubmitting(true)

    const email = query.get('email')
    const token = query.get('token')
    const source = query.get('source')

    if (!email || !token) {
      setGenericError(
        'There was an error. Please try again later or contact our support.'
      )
      return
    }

    try {
      await APIService.changePassword(email, token, password, passwordConfirm)
      setShowSuccessMessage(true)

      if (source !== 'patient_app') {
        timerLogin.current = setTimeout(() => {
          navigate('/login', { replace: true })
        }, 5000)
      }
    } catch (error: any) {
      setIsSubmitting(false)
      setPassword('')
      setPasswordConfirm('')

      const errorPassword = error?.response?.data?.errors?.password?.[0]
      const errorPasswordConfirm =
        error?.response?.data?.errors?.password_confirmation?.[0]
      if (errorPassword) {
        setPasswordError(errorPassword)
      } else if (errorPasswordConfirm) {
        setPasswordError(errorPasswordConfirm)
      } else {
        setGenericError(
          'There was an error. Please try again later or contact our support.'
        )
      }
    }
  }

  const canSubmitForm = (): boolean => {
    if (
      password &&
      password.length >= 1 &&
      passwordConfirm &&
      passwordConfirm.length >= 1 &&
      password === passwordConfirm
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    return () => {
      if (timerLogin.current) {
        clearTimeout(timerLogin.current)
      }
    }
  }, [])

  return (
    <div id="auth">
      <div className="row h-100">
        <div className="col-12">
          <div id="auth-left">
            <div className="auth-logo">
              <img
                src="/assets/logo/logo-dottore-london-trasp-768x444.png"
                alt="Logo"
              />
            </div>

            {genericError && (
              <>
                <h5 className="text-center mb-3">{genericError}</h5>
                <div className="text-center">
                  <Link
                    to="/login"
                    className="btn btn-lg btn-outline-primary mt-3"
                  >
                    Go to login
                  </Link>
                </div>
              </>
            )}

            {!genericError && (
              <>
                {!showSuccessMessage && (
                  <form onSubmit={handleChangePassword}>
                    <div className="form-group position-relative has-icon-left mb-4">
                      <input
                        type="password"
                        className={`form-control form-control-xl ${
                          passwordError !== null ? 'is-invalid' : ''
                        }`}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="current-password"
                      />
                      <div className="form-control-icon">
                        <i className="bi bi-shield-lock"></i>
                      </div>
                      {passwordError !== null && (
                        <div className="invalid-feedback">
                          <i className="bx bx-radio-circle"></i>
                          {passwordError}
                        </div>
                      )}
                    </div>
                    <div className="form-group position-relative has-icon-left mb-4">
                      <input
                        type="password"
                        className={`form-control form-control-xl ${
                          passwordError !== null ? 'is-invalid' : ''
                        }`}
                        placeholder="Confirm password"
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        autoComplete="current-password"
                      />
                      <div className="form-control-icon">
                        <i className="bi bi-shield-lock"></i>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary btn-block btn-lg shadow-lg mt-3"
                      disabled={isSubmitting || !canSubmitForm()}
                    >
                      {isSubmitting === false ? (
                        <>Change password</>
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </form>
                )}

                {showSuccessMessage && (
                  <>
                    <h5 className="text-center mb-3">
                      Your password has been changed successfully.
                    </h5>
                    {query.get('source') === 'patient_app' ? (
                      <>
                        <h6 className="text-center mb-3">
                          You can now close this page and return to the app.
                        </h6>
                        <h6 className="text-center">
                          <Button
                            onClick={(e) => {
                              e.preventDefault()
                              setTimeout(function () {
                                // window.location.href =
                                //   'https://itunes.apple.com/appdir'
                                window.location.href =
                                  'https://www.dottorelondon.com'
                              }, 300)
                              window.location.href = 'dottorelondon://'
                            }}
                            className="btn btn-lg btn-outline-primary mt-3"
                          >
                            Go to the app
                          </Button>
                        </h6>
                      </>
                    ) : (
                      <>
                        <h6 className="text-center mb-3">
                          You are now being redirected to the login page.
                        </h6>
                        <h6 className="text-center">
                          <Link
                            to="/login"
                            className="btn btn-lg btn-outline-primary mt-3"
                          >
                            Go to login
                          </Link>
                        </h6>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PasswordChangePage
